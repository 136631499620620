<template>
    <CardLayout :item="item" :keys="keys" no_footer v-if="false">
        <template v-slot:header>
            <v-row no-gutters justify="center">
                <v-col cols="12" class="pb-0" :md="6">
                        <span class="font-weight-bold">
                            {{item.registration}} • <span class="gold--text">{{item.id}}</span>
                        </span>
                </v-col>
                <v-col cols="12" :md="6" class="font-weight-medium">
                    <span class="gold white--text px-3 rounded">{{item.position}}</span>
                </v-col>
            </v-row>
        </template>
        <template v-slot:content></template>
    </CardLayout>

    <v-hover v-slot:default="{hover}" v-else>
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">

            <v-card-text class="card-state pa-1" :class="cardState">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="6">
                        <span class="font-weight-bold">
                            {{item.registration}} • <span class="gold--text">{{item.id}}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="6" class="font-weight-medium">
                        <span class="gold white--text px-3 rounded">{{item.position}}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-tie</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.name }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>

                        <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t('sellers_list.table_keys.active_days') }}</v-col>
                                    <v-col cols="12" class="body-2 font-weight-medium">
                                        <span v-if="!item.active" class="tag-status state-red white--text px-2 py-1 ml-1 rounded">
                                            <v-icon left size="15" dark style="margin-bottom: 2px;">mdi-close-circle</v-icon>
                                                {{ $t('seller.status.inactive') }}
                                        </span>
                                        <span v-else class="tag-status state-green white--text px-2 py-1 ml-1 rounded">
                                            <v-icon left size="15" dark style="margin-bottom: 3px;">mdi-check-circle</v-icon>
                                                {{ $t('seller.status.active') }} ({{item.active_days}})
                                        </span>
                                    </v-col>

                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t('sellers_list.table_keys.certificates') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ g_isDefined(item.certificates) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t('sellers_list.table_keys.quick_start') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ g_isDefined(item.quick_start) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t('sellers_list.table_keys.phone') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ g_isDefined(item.phone) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-col>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="width: calc(100% / 11)">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ $t('sellers_list.table_keys.' + v.value) }}</span>
                                </v-row>

                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{item}}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
import CardLayout from "@/components/layouts/CardLayout";

export default {
    name: "SellerCard",
    components: {CardLayout},
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        card: { cols: 6, xs: 6, sm: 3, md: 3, align: 'center', class: 'my-2'}
    }),
    computed: {
        layout() {
            return this.card;
        },

        cardState() {
            if (this.item.disabled) {
                return 'state-grey'
            } else if (!this.item.active) {
                return 'state-red'
            } else {
                return ''
            }
        }
    }
}
</script>